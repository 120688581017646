import React, { Component } from "react";
import { Map, GoogleApiWrapper, Marker, InfoWindow } from "google-maps-react";
class MapContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      confinements: [],
      activeMarker: null,
      selectedConfinement: null,
    };
  }

  componentDidMount() {
    fetch("/api/confinements")
      .then((response) => response.json())
      .then((data) => {
        console.log(data);
        this.setState({ confinements: data });
      })
      .catch((error) => {
        console.error("Error fetching confinement data:", error);
      });
  }

  onMarkerClick = (props, marker, e) => {
    const selectedConfinement = props.data;
    console.log("Marker clicked:", selectedConfinement);
    if (selectedConfinement) {
      this.setState({
        activeMarker: marker,
        selectedConfinement: selectedConfinement,
      });

      this.props.setSelectedConfinement(selectedConfinement);
    }
  };
  render() {
    const mapStyles = {
      width: "100%",
      height: "500px",
    };

    return (
      <div>
        <Map
          google={this.props.google}
          style={mapStyles}
          initialCenter={{
            lat: 42.12,
            lng: -93.6496,
          }}
          zoom={7}
        >
          {this.state.confinements.map((confinement, index) => (
            <Marker
              key={index}
              title={confinement.name}
              name={confinement.name}
              position={{
                lat: confinement.lat,
                lng: confinement.long,
              }}
              data={confinement}
              onClick={this.onMarkerClick}
            />
          ))}

          <InfoWindow
            marker={this.state.activeMarker}
            visible={!!this.state.activeMarker}
          >
            <div>
              {this.state.selectedConfinement && (
                <div>
                  <h4>{this.state.selectedConfinement.name}</h4>
                  <p>
                    {this.state.selectedConfinement.location},{" "}
                    {this.state.selectedConfinement.city},{" "}
                    {this.state.selectedConfinement.state} <br />
                    {/* <a
                      href={`/confinements/${this.state.selectedConfinement.id}`}
                      rel="noopener noreferrer"
                      className="underline hover:text-glaucous-200 "
                    >
                      More Info
                    </a> */}
                  </p>
                </div>
              )}
            </div>
          </InfoWindow>
        </Map>
      </div>
    );
  }
}

export default GoogleApiWrapper({
  apiKey: "AIzaSyDClbyPWe7rQ5oskzZ8ff5Mrf1Ikz8yw2M",
})(MapContainer);
