import React from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";

const validationSchema = Yup.object().shape({
  first_name: Yup.string().required("First Name is required"),
  last_name: Yup.string().required("Last Name is required"),
  email: Yup.string()
    .required("Email is required")
    .email("Invalid email format")
    .test(
      "at-symbol",
      "Email must contain @ symbol",
      (value) => value && value.includes("@")
    ),
  password: Yup.string()
    .required("Password is required")
    .min(6, "Password must be at least 6 characters long"),
});

export const SignUpForm = ({ onLogin, setAlerts }) => {
  const initialValues = {
    first_name: "",
    last_name: "",
    email: "",
    password: "",
  };

  const handleSubmit = (values, { setSubmitting, setErrors }) => {
    console.log("I was clicked");

    fetch("/api/signup", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(values),
    })
      .then((r) => {
        setSubmitting(false);
        if (r.ok) {
          r.json().then((user) => {
            setAlerts([]);
            onLogin(user);
          });
        } else {
          if (r.status === 500) {
            console.error("Server Error: Internal Server Error");
          } else {
            r.json().then((err) => setErrors(err.errors));
          }
        }
      })
      .catch((error) => {
        console.error("Network error:", error);
      });
  };

  return (
    <div className="bg-alice_blue p-4 rounded-lg">
      <h2 className="text-2xl font-semibold text-glaucous-200  mb-4">
        Sign up for an Account
      </h2>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {({ isSubmitting }) => (
          <Form>
            <div className="mb-4">
              <label htmlFor="first_name" className="text-glaucous-200">
                First Name
              </label>
              <Field
                type="text"
                id="first_name"
                name="first_name"
                autoComplete="off"
                className="w-full p-2 rounded-lg"
              />
              <ErrorMessage
                name="first_name"
                component="div"
                className="text-red-600"
              />
            </div>
            <div className="mb-4">
              <label htmlFor="last_name" className="text-glaucous-200">
                Last Name
              </label>
              <Field
                type="text"
                id="last_name"
                name="last_name"
                autoComplete="off"
                className="w-full p-2 rounded-lg"
              />
              <ErrorMessage
                name="last_name"
                component="div"
                className="text-red-600"
              />
            </div>
            <div className="mb-4">
              <label htmlFor="email" className="text-glaucous-200">
                Email
              </label>
              <Field
                type="text"
                id="email"
                name="email"
                autoComplete="off"
                className="w-full p-2 rounded-lg"
              />
              <ErrorMessage
                name="email"
                component="div"
                className="text-red-600"
              />
            </div>
            <div className="mb-4">
              <label htmlFor="password" className="text-glaucous-200">
                Password
              </label>
              <Field
                type="password"
                id="password"
                name="password"
                autoComplete="current-password"
                className="w-full p-2 rounded-lg"
              />
              <ErrorMessage
                name="password"
                component="div"
                className="text-red-600"
              />
            </div>
            <button
              type="submit"
              disabled={isSubmitting}
              className="text-white font-semibold bg-glaucous-200 px-8 py-3 rounded-full hover:bg-glaucous-900 hover:text-glaucous-200 transition duration-300 ease-in-out transform hover:scale-105 mb-4"
            >
              {isSubmitting ? "Loading..." : "Sign Up"}
            </button>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default SignUpForm;
