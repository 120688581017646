import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";

function CountyDetail() {
  const [county, setCounty] = useState(null);
  const { id } = useParams();

  const navigate = useNavigate();

  useEffect(() => {
    fetch(`/api/counties/${id}`)
      .then((response) => {
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
        return response.json();
      })
      .then((data) => {
        setCounty(data);
      })
      .catch((error) => {
        console.error("Error fetching County details:", error);
      });
  }, [id]);

  if (!county) {
    return <p>Loading...</p>;
  }

  return (
    <div className="p-4">
      {/* <button
        onClick={() => navigate("/counties")}
        className="bg-ucla_blue text-alice_blue px-3 py-2 rounded-full mb-4 hover:bg-lapis_lazuli hover:text-alice_blue"
      >
        Back to All Counties
      </button> */}
      <h1 className="text-4xl font-extrabold text-glaucous-200 mb-2">
        {county.name} County
      </h1>
      <div className="bg-alice_blue p-4 rounded-lg">
        <p>
          <strong className="text-lg font-semibold text-glaucous-200 mb-2">
            DNR Field Office {county.field_office_number}:
          </strong>{" "}
          <span className="text-glaucous-200 text-lg">
            {county.field_office_name}
          </span>
        </p>

        <p>
          <strong className="text-lg font-semibold text-glaucous-200 mb-2">
            Total confinements:
          </strong>{" "}
          <span className="text-glaucous-200 text-lg">
            {county.confinements.length.toLocaleString()}
          </span>
        </p>
      </div>
      {/* <h2 className="text-xl font-semibold text-lapis_lazuli mt-4">
        Animal Units
      </h2> */}
      <h2 className="text-xl font-semibold  text-glaucous-200 mt-4">
        Confinements
      </h2>
      <ul className="space-y-4">
        {county.confinements.map((confinement) => (
          <li
            key={confinement.id}
            className="border border-glaucous-900 p-4 rounded-lg "
          >
            <p className="font-semibold text-lg text-glaucous-100">
              {confinement.name}
            </p>
            <p className="text-glaucous-100">
              {confinement.location}, {confinement.city}, {confinement.state}
            </p>
            <button
              onClick={() => navigate(`/confinements/${confinement.id}`)}
              className="text-glaucous-200 font-semibold px-10 py-3 mt-2 rounded-full bg-glaucous-900 hover:bg-glaucous-200 hover:text-glaucous-900 transition duration-300 ease-in-out transform hover:scale-105"
            >
              More Info
            </button>
          </li>
        ))}
      </ul>
    </div>
  );
}

export default CountyDetail;
