import React, { useState, useEffect } from "react";
import { Route, Routes } from "react-router-dom";
import { CountiesPage } from "./CountiesPage.js";
import { ConfinementsPage } from "./ConfinementsPage.js";
import Home from "./Home.js";
import Navbar from "./NavBar.js";
import CountyDetail from "./CountyDetail.js";
import ConfinementDetail from "./ConfinementDetail.js";
import { NotFound } from "./NotFound.js";
import LoginForm from "./LoginForm.js";
import Dashboard from "./Dashboard.js";
import SignUpForm from "./SignUpForm.js";
import { CountiesProvider } from "../context/CountiesContext";
import { ConfinementsProvider } from "../context/ConfinementsContext";
import Footer from "./Footer.js";
import About from "./About.js";

function App() {
  const [user, setUser] = useState(null);

  useEffect(() => {
    fetch("/api/check_session").then((r) => {
      if (r.ok) {
        r.json().then((user) => setUser(user));
      }
    });
  }, []);

  return (
    <CountiesProvider>
      <ConfinementsProvider>
        <div>
          <Navbar user={user} setUser={setUser} />
          <main>
            <Routes>
              <Route path="/" element={<Home />} />
              <Route path="*" element={<NotFound />} />
              <Route path="about" element={<About />} />
              <Route path="/counties" element={<CountiesPage />} />
              <Route path="/login" element={<LoginForm />} />
              <Route path="/signup" element={<SignUpForm />} />
              <Route
                path="/dashboard"
                element={<Dashboard user={user} setUser={setUser} />}
              />
              <Route path="/counties/:id" element={<CountyDetail />} />
              <Route path="/confinements" element={<ConfinementsPage />} />
              <Route path="/confinements/:id" element={<ConfinementDetail />} />
            </Routes>
          </main>
          <Footer />
        </div>
      </ConfinementsProvider>
    </CountiesProvider>
  );
}

export default App;
