import React, { useState } from "react";
import MapContainer from "./MapContainer";
import { Link } from "react-router-dom";
import { useConfinements } from "../context/ConfinementsContext";

export const ConfinementsPage = () => {
  const [selectedConfinement, setSelectedConfinement] = useState(null);
  const confinements = useConfinements();

  return (
    <div className="p-4 bg-white">
      <h1 className="text-4xl font-extrabold text-glaucous-200 mb-2">
        Iowa's Animal Confinements
      </h1>
      <p>
        <strong className="text-2xl font-semibold text-glaucous-200 mb-2">
          Total confinements:
        </strong>{" "}
        <span className="text-glaucous-200 text-lg">
          {confinements.length.toLocaleString()}
        </span>
      </p>

      {selectedConfinement ? (
        <div className="border border-alice_blue p-4 rounded-lg mt-2 mb-5">
          <h3 className="text-lg font-semibold">{selectedConfinement.name}</h3>
          <p>
            {selectedConfinement.location}, {selectedConfinement.city},{" "}
            {selectedConfinement.state}
          </p>
          <p>{selectedConfinement.operation_type}</p>
          <div className="mt-6 mb-4">
            <Link
              to={`/confinements/${selectedConfinement.id}`}
              className="text-glaucous-200 font-semibold px-10 py-4 rounded-full bg-glaucous-900 hover:bg-glaucous-200 hover:text-glaucous-900 transition duration-300 ease-in-out transform hover:scale-105"
            >
              More Info &#8599;
            </Link>
          </div>
        </div>
      ) : null}

      <div className="mt-6">
        <MapContainer setSelectedConfinement={setSelectedConfinement} />
      </div>
    </div>
  );
};
