import React from "react";

const Footer = () => {
  return (
    <footer className="bg-glaucous-900 text-asparagus py-8 text-left">
      <div className="container mx-auto flex flex-col md:flex-row items-center md:items-start justify-between">
        <div className="mb-4 md:mb-0">
          <p className="text-glaucous-200 text-sm font-semibold mb-2">
            The Daylight Project
          </p>
          <a href="/about" className="text-glaucous-200 text-sm">
            About Us
          </a>
        </div>
        <hr className="border-asparagus my-4 hidden md:block" />
        <p className="text-glaucous-200 text-sm mt-7">
          &copy; {new Date().getFullYear()} Iowa Confinement Daylighting Project
        </p>
      </div>
    </footer>
  );
};

export default Footer;
