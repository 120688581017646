import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { Map, GoogleApiWrapper, Marker } from "google-maps-react";

function ConfinementDetail(props) {
  const [confinement, setConfinement] = useState(null);
  const { id } = useParams();

  useEffect(() => {
    console.log("Fetching confinement details for ID:", id);
    fetch(`/api/confinements/${id}`)
      .then((response) => {
        console.log(response);
        if (!response.ok) {
          console.error(`HTTP error! Status: ${response.status}`);
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
        return response.json();
      })
      .then((data) => {
        setConfinement(data);
        console.log("Fetched data:", data);
      })
      .catch((error) => {
        console.error("Error fetching Confinement details:", error);
      });
  }, [id]);

  if (!confinement) {
    return <p>Loading...</p>;
  }

  return (
    <div className="p-4 bg-white">
      <h1 className="text-4xl font-extrabold text-glaucous-200 mb-4">
        Iowa's Confinements
      </h1>
      <div className="border border-alice_blue p-4 rounded-lg mb-5">
        <p className="text-2xl font-semibold">{confinement.name}</p>
        <p className="text-lg">
          {confinement.city}, {confinement.location}, {confinement.state}
        </p>
        <a
          className="text-blue-500 hover:underline"
          href={`/counties/${confinement.county_id}`}
        >
          {confinement.county_name} County
        </a>

        <p className="mt-4">
          For additional information about this confinement, including the
          contact name, owner name, manure management plan, and construction
          details, visit the{" "}
          <a
            href={confinement.web}
            target="_blank"
            rel="noopener noreferrer"
            className="text-blue-500 hover:underline"
          >
            Iowa DNR Website
          </a>
          {"."}
        </p>

        <h2 className="text-xl font-semibold text-lapis_lazuli mt-4">
          Animal Units
        </h2>
        <div className="font-semibold">
          {confinement.animal_unit.swine !== null && (
            <p>Swine: {confinement.animal_unit.swine.toLocaleString()}</p>
          )}
          {confinement.animal_unit.beef_cattle !== null && (
            <p>
              Beef Cattle:{" "}
              {confinement.animal_unit.beef_cattle.toLocaleString()}
            </p>
          )}
          {confinement.animal_unit.dairy_cattle !== null && (
            <p>
              Dairy Cattle:{" "}
              {confinement.animal_unit.dairy_cattle.toLocaleString()}
            </p>
          )}
          {confinement.animal_unit.chickens !== null && (
            <p>Chickens: {confinement.animal_unit.chickens.toLocaleString()}</p>
          )}
          {confinement.animal_unit.turkeys !== null && (
            <p>Turkeys: {confinement.animal_unit.turkeys.toLocaleString()}</p>
          )}
          {confinement.animal_unit.sheep_goat !== null && (
            <p>
              Sheep & Goat:{" "}
              {confinement.animal_unit.sheep_goat.toLocaleString()}
            </p>
          )}
          {confinement.animal_unit.horses !== null && (
            <p>Horses: {confinement.animal_unit.horses.toLocaleString()}</p>
          )}
          {confinement.animal_unit.other !== null &&
            confinement.animal_unit.other !== 0 && (
              <p>Other: {confinement.animal_unit.other.toLocaleString()}</p>
            )}
        </div>
      </div>

      <Map
        google={props.google}
        initialCenter={{
          lat: confinement.lat,
          lng: confinement.long,
        }}
        zoom={15}
        style={{ width: "100%", height: "400px" }}
      >
        <Marker
          title={confinement.name}
          position={{
            lat: confinement.lat,
            lng: confinement.long,
          }}
        />
      </Map>
    </div>
  );
}

export default GoogleApiWrapper({
  apiKey: "AIzaSyDClbyPWe7rQ5oskzZ8ff5Mrf1Ikz8yw2M",
})(ConfinementDetail);
