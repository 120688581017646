import React from "react";

export const About = () => {
  return (
    <div className="min-h-screen flex flex-col justify-center items-center p-6">
      <div className="max-w-3xl text-left">
        <h1 className="text-4xl font-extrabold text-glaucous-200 mb-6">
          About Us
        </h1>
        <p className="text-glaucous-200 text-lg mb-4">
          Welcome to the Iowa Confinements Daylight Project. We're dedicated to
          bringing light to Iowa's animal confinements and their impact on our
          natural resources, drinking water, and the quality of life in rural
          communities.
        </p>
        <p className="text-glaucous-200 text-lg mb-4">
          Our mission is to make data about animal confinements more accessible
          and understandable while providing resources for Iowans to prevent a
          confinement from being built in their community.
        </p>
        <hr className="my-6" />
        <h2 className="text-2xl font-extrabold text-glaucous-200 mb-4">
          Contact
        </h2>
        <p className="text-glaucous-200 text-lg">
          Send us an email at{" "}
          <a
            href="mailto:IowaConfinements@gmail.com"
            className="text-lapis_lazuli hover:underline"
          >
            IowaConfinements@gmail.com
          </a>
          .
        </p>
      </div>
    </div>
  );
};

export default About;
