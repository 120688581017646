import React, { useState, useEffect } from "react";
import LoginForm from "./LoginForm";
import SignUpForm from "./SignUpForm";
import AlertForm from "./AlertForm";
import { useCounties } from "../context/CountiesContext";

export const Dashboard = ({ user, setUser }) => {
  const [showLogin, setShowLogin] = useState(true);
  const [showAlertForm, setShowAlertForm] = useState(false);
  const [editingAlert, setEditingAlert] = useState(null);
  const counties = useCounties();
  const [alerts, setAlerts] = useState([]);

  useEffect(() => {
    fetch("/api/alerts")
      .then((r) => r.json())
      .then(setAlerts);
  }, []);

  const toggleAlertForm = (show, alertToEdit = null) => {
    setShowAlertForm(show);
    setEditingAlert(alertToEdit);
  };

  const addAlert = (newAlert) => {
    const selectedCounties = newAlert.selectedCounties;
    const emailListsString = selectedCounties.join(", ");
    const requestData = {
      email_lists: emailListsString,
    };

    if (editingAlert) {
      const alertId = editingAlert.id;
      fetch(`/api/alerts/${alertId}`, {
        method: "PATCH",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(requestData),
      })
        .then((response) => {
          if (response.status === 200) {
            return response.json();
          } else {
            throw new Error("Failed to update the alert.");
          }
        })
        .then((data) => {
          const updatedAlerts = alerts.map((alert) =>
            alert.id === alertId ? data : alert
          );
          setAlerts(updatedAlerts);
          toggleAlertForm(false);
          setEditingAlert(null);
        })
        .catch((error) => {
          console.error(error);
        });
    } else {
      fetch("/api/alerts", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(requestData),
      })
        .then((response) => {
          if (response.status === 201) {
            return response.json();
          } else {
            throw new Error("Failed to create the alert.");
          }
        })
        .then((data) => {
          setAlerts([...alerts, data]);
          toggleAlertForm(false);
        })
        .catch((error) => {
          console.error(error);
        });
    }
  };

  function handleEditAlert(alertToEdit) {
    toggleAlertForm(true, alertToEdit);
  }

  function handleCancelEdit() {
    toggleAlertForm(false);
  }

  function handleDeleteAlert(id) {
    fetch(`/api/alerts/${id}`, { method: "DELETE" }).then((r) => {
      if (r.ok) {
        setAlerts((alerts) => alerts.filter((alert) => alert.id !== id));
      }
    });
  }

  return (
    <div className="py-6 sm:py-12">
      <div className="sm:mx-auto sm:w-full sm:max-w-md p-4 bg-glaucous-900 rounded-lg shadow-md">
        <h2 className="text-4xl font-extrabold text-glaucous-200 mb-2">
          User Dashboard
        </h2>
        {user ? (
          <div>
            <h2 className="text-lg font-semibold text-glaucous-200 mb-2">
              Hello, {user.first_name}.
            </h2>
            <div className="bg-white rounded p-3 mb-4">
              <h3 className="text-lg font-semibold text-glaucous-200 mb-2">
                Account Details
              </h3>
              <p className="text-glaucous-200">
                <span className="font-semibold">First name:</span>{" "}
                {user.first_name}
              </p>
              <p className="text-glaucous-200">
                <span className="font-semibold">Last name:</span>{" "}
                {user.last_name}
              </p>
              <p className="text-glaucous-200">
                <span className="font-semibold">Email:</span> {user.email}
              </p>
            </div>
            <div className="bg-white rounded p-3 mb-4">
              <h3 className="text-lg font-semibold text-glaucous-200 mb-2">
                Email Alerts
              </h3>
              {showAlertForm ? (
                <AlertForm
                  counties={counties}
                  addAlert={addAlert}
                  onCancelEdit={handleCancelEdit}
                  editingAlert={editingAlert}
                />
              ) : (
                <button
                  onClick={() => toggleAlertForm(true)}
                  className="text-white font-semibold bg-glaucous-200 px-5 py-3 rounded-full hover:bg-glaucous-900 hover:text-glaucous-200 transition duration-300 ease-in-out transform hover:scale-105 mb-4"
                >
                  Create Alert
                </button>
              )}
              {alerts.length > 0 && (
                <div>
                  <ul className="space-y-2">
                    {alerts.map((alert, index) => (
                      <li
                        key={index}
                        className="border border-glaucous-900 p-1 rounded-lg text-lg flex flex-col cursor-pointer transition-transform duration-200 ease-in-out transform "
                      >
                        <div className="p-4">
                          <p className="text-glaucous-200 font-semibold">
                            {alert.email_lists}
                          </p>
                        </div>
                        <div className="px-4 py-1 bg-white text-right text-lg">
                          <button
                            onClick={() => handleEditAlert(alert)}
                            className="text-glaucous-200 hover:text-glaucous-800"
                          >
                            Edit
                          </button>
                          <button
                            onClick={() => handleDeleteAlert(alert.id)}
                            className="ml-3 text-glaucous-200 hover:text-glaucous-800"
                          >
                            Delete
                          </button>
                        </div>
                      </li>
                    ))}
                  </ul>
                </div>
              )}
            </div>
          </div>
        ) : (
          <div>
            {showLogin ? (
              <>
                <LoginForm onLogin={setUser} />
                <p className="mt-2 text-center text-glaucous-200">
                  Don't have an account?{" "}
                  <button
                    onClick={() => setShowLogin(false)}
                    className="text-ucla_blue hover:text-glaucous-200"
                  >
                    Sign Up
                  </button>
                </p>
              </>
            ) : (
              <>
                <SignUpForm onLogin={setUser} setAlerts={setAlerts} />
                <p className="mt-2 text-center text-glaucous-200">
                  Already have an account?{" "}
                  <button
                    onClick={() => setShowLogin(true)}
                    className="text-ucla_blue hover:text-glaucous-200"
                  >
                    Log In
                  </button>
                </p>
              </>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default Dashboard;
