import React, { useState } from "react";
import { useCounties } from "../context/CountiesContext";
import { Link } from "react-router-dom";

export const CountiesPage = () => {
  const counties = useCounties();

  const [sortBy, setSortBy] = useState("name");

  const sortCounties = (counties, sortBy) => {
    if (sortBy === "name") {
      return counties.slice().sort((a, b) => a.name.localeCompare(b.name));
    } else if (sortBy === "confinements") {
      return counties
        .slice()
        .sort((a, b) => b.confinements.length - a.confinements.length);
    }
    return counties;
  };

  const sortedCounties = sortCounties(counties, sortBy);

  const handleSortChange = (event) => {
    setSortBy(event.target.value);
  };

  return (
    <div className="p-4">
      <h1 className="text-4xl font-extrabold text-glaucous-200 mb-4">
        County Data
      </h1>

      <div className="flex items-center mb-4">
        <label className="mr-4 text-glaucous-200">Sort:</label>
        <select
          value={sortBy}
          onChange={handleSortChange}
          className="px-4 py-2 rounded-full bg-glaucous-900 text-glaucous-200 border border-alice_blue hover:border-ucla_blue cursor-pointer"
        >
          <option value="name">Alphabetically</option>
          <option value="confinements">By Confinements</option>
        </select>
      </div>

      <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-5 gap-4">
        {sortedCounties.map((county) => (
          <Link
            key={county.id}
            to={`/counties/${county.id}`}
            className="hover:bg-glaucous-900 border border-alice_blue p-4 rounded-lg flex flex-col cursor-pointer transition-transform duration-200 ease-in-out transform hover:scale-105 hover:bg-ucla_blue-800"
          >
            <p className="text-lg font-semibold mb-2">{county.name}</p>
            <p className="text-sm mb-4 text-gray-600">
              {county.confinements.length} Confinements
            </p>
          </Link>
        ))}
      </div>
    </div>
  );
};
