import React from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";

const validationSchema = Yup.object().shape({
  email: Yup.string().required("Email is required"),
  password: Yup.string().required("Password is required"),
});

export const LoginForm = ({ onLogin }) => {
  const initialValues = {
    email: "",
    password: "",
  };

  const handleSubmit = (values, { setSubmitting, setErrors }) => {
    setSubmitting(true);
    fetch("/api/login", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(values),
    })
      .then((r) => {
        setSubmitting(false);
        if (r.ok) {
          r.json().then((user) => onLogin(user));
        } else {
          r.json().then((err) => setErrors(err.errors));
        }
      })
      .catch((error) => {
        console.error("Network error:", error);
        setSubmitting(false);
      });
  };

  return (
    <div className="bg-alice_blue p-4 rounded-lg">
      <h2 className="text-2xl font-semibold text-glaucous-200 mb-4">
        Log into Your Account
      </h2>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {({ isSubmitting }) => (
          <Form>
            <div className="mb-4">
              <label htmlFor="email" className="text-glaucous-200">
                Email
              </label>
              <Field
                type="text"
                id="email"
                name="email"
                autoComplete="off"
                className="w-full p-2 rounded-lg"
              />
              <ErrorMessage
                name="email"
                component="div"
                className="text-red-600"
              />
            </div>
            <div className="mb-4">
              <label htmlFor="password" className="text-glaucous-200">
                Password
              </label>
              <Field
                type="password"
                id="password"
                name="password"
                autoComplete="current-password"
                className="w-full p-2 rounded-lg"
              />
              <ErrorMessage
                name="password"
                component="div"
                className="text-red-600"
              />
            </div>
            <button
              type="submit"
              disabled={isSubmitting}
              className="text-white font-semibold bg-glaucous-200 px-8 py-3 rounded-full hover:bg-glaucous-900 hover:text-glaucous-200 transition duration-300 ease-in-out transform hover:scale-105 mb-4"
            >
              {isSubmitting ? "Loading..." : "Login"}
            </button>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default LoginForm;
