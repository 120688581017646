import React from "react";
import { Link } from "react-router-dom";

export const Home = () => {
  return (
    <div className="min-h-screen flex flex-col justify-center items-center p-6">
      <div className="max-w-3xl text-left">
        <h1 className="text-4xl font-extrabold text-glaucous-200 mb-6">
          Iowa Confinements Daylight Project
        </h1>

        <p className="text-glaucous-200 text-lg mb-4">
          We've pulled data from the Iowa Department of Natural Resources about
          the state's animal confinements and compiled it in a more visual and
          simpler way to make it more understandable and accessible to Iowans.
        </p>
        <hr className="mb-6 mt-6" />
        <p className="text-glaucous-200 text-lg mb-9">
          Explore the state's confinements in our statewide map or by county. To
          be notified of proposed confinements by county, sign up for an account
          to receive an email when a new application is submitted to the Iowa
          Department of Natural Resources.
        </p>
        <div className="flex">
          <Link
            to="/confinements"
            className="text-white font-semibold bg-glaucous-200 px-10 py-4 rounded-full hover:bg-glaucous-900 hover:text-glaucous-200 transition duration-300 ease-in-out transform hover:scale-105"
          >
            Interactive Map
          </Link>
          <Link
            to="/counties"
            className="text-white font-semibold bg-glaucous-200 px-10 py-4 rounded-full hover:bg-glaucous-900 hover:text-glaucous-200 transition duration-300 ease-in-out transform hover:scale-105 ml-5"
          >
            County Data
          </Link>
        </div>
      </div>
    </div>
  );
};

export default Home;
