import React, { createContext, useContext, useState, useEffect } from "react";

const ConfinementsContext = createContext();

export const useConfinements = () => {
  return useContext(ConfinementsContext);
};

export const ConfinementsProvider = ({ children }) => {
  const [confinements, setConfinements] = useState([]);

  useEffect(() => {
    fetch("/api/confinements")
      .then((response) => {
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
        return response.json();
      })
      .then((data) => {
        setConfinements(data);
      })
      .catch((error) => {
        console.error("Error fetching Confinements:", error);
      });
  }, []);

  return (
    <ConfinementsContext.Provider value={confinements}>
      {children}
    </ConfinementsContext.Provider>
  );
};
