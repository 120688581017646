import React, { useState } from "react";
import { Link } from "react-router-dom";

function Navbar({ user, setUser }) {
  const [navOpen, setNavOpen] = useState(false);

  // function handleLogoutClick() {
  //   fetch("/api/logout", { method: "DELETE" }).then((r) => {
  //     if (r.ok) {
  //       setUser(null);
  //     }
  //   });
  // }

  // const toggleNav = () => {
  //   setNavOpen(!navOpen);
  // };

  const closeNav = () => {
    setNavOpen(false);
  };

  return (
    <nav className="bg-white px-0 p-4 flex justify-between items-center relative">
      <div className="absolute w-full bottom-0 h-0.5 bg-glaucous-900"></div>
      <div className="lg:flex hidden">
        <ul className="flex">
          <li>
            <Link
              to="/"
              className="text-glaucous-200 text-m hover:underline mr-5 px-4"
            >
              Home
            </Link>
          </li>
          <li>
            <Link
              to="/confinements"
              className="text-glaucous-200 text-m hover:underline mr-5 px-4"
            >
              Confinements
            </Link>
          </li>
          <li>
            <Link
              to="/counties"
              className="text-glaucous-200 text-m  hover:underline mr-5 px-4"
            >
              Counties
            </Link>
          </li>
          {/* <li>
            <Link
              to="/dashboard"
              className="text-glaucous-200 text-m  hover:underline mr-5 px-4"
            >
              Dashboard
            </Link>
          </li> */}
        </ul>
      </div>
      {/* <div className="lg:hidden ">
        <button
          onClick={toggleNav}
          className="text-glaucous-200 text-m hover:underline px-4"
        >
          <span className="block w-6 h-1 bg-glaucous-200 mb-1"></span>
          <span className="block w-6 h-1 bg-glaucous-200 mb-1"></span>
          <span className="block w-6 h-1 bg-glaucous-200"></span>
        </button>
      </div> */}
      {/* <div>
        {user ? (
          <button
            onClick={handleLogoutClick}
            className="text-white font-semibold bg-glaucous-200 px-5 py-2.5 rounded-full hover:bg-glaucous-900 hover:text-glaucous-200 transition duration-300 ease-in-out transform hover:scale-105"
          >
            Log Out
          </button>
        ) : (
          <Link
            to="/dashboard"
            className="text-white font-semibold bg-glaucous-200 px-5 py-2.5 rounded-full hover:bg-glaucous-900 hover:text-glaucous-200 transition duration-300 ease-in-out transform hover:scale-105"
          >
            Log In
          </Link>
        )}
      </div> */}
      {navOpen && (
        <div className="lg:hidden">
          <ul className="bg-white absolute left-2 top-16 flex flex-col space-y-2 p-4 border border-gray-300">
            <li className="hover:bg-ultra_violet-900">
              <Link to="/" onClick={closeNav}>
                Home
              </Link>
            </li>
            <li className="hover:bg-ultra_violet-900">
              <Link to="/confinements" onClick={closeNav}>
                Confinements
              </Link>
            </li>
            <li className="hover:bg-ultra_violet-900">
              <Link to="/counties" onClick={closeNav}>
                Counties
              </Link>
            </li>
            <li className="hover:bg-ultra_violet-900">
              <Link to="/dashboard" onClick={closeNav}>
                Dashboard
              </Link>
            </li>
          </ul>
        </div>
      )}
    </nav>
  );
}

export default Navbar;
