import React from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";

const AlertForm = ({ counties, addAlert, editingAlert }) => {
  const initialValues = {
    selectedCounties: editingAlert ? editingAlert.email_lists.split(", ") : [],
    selectAll: false,
  };

  const validationSchema = Yup.object().shape({
    selectedCounties: Yup.array()
      .min(1, "Please select at least one county")
      .of(Yup.string()),
  });

  const handleSubmit = (values, formikBag) => {
    addAlert(values, formikBag);
  };

  const handleSelectAll = (setFieldValue, selectAll) => {
    const allCountyNames = counties.map((county) => county.name);
    setFieldValue("selectedCounties", selectAll ? allCountyNames : []);
  };

  const columns = 3;
  const columnWidth = Math.ceil(counties.length / columns);

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
    >
      {({ values, setFieldValue }) => (
        <Form className="p-4 rounded-lg">
          <h2 className="text-lg font-semibold text-glaucous-200 mb-2">
            Create Alert
          </h2>
          <div className="mb-4">
            <label className="block text-glaucous-200 mb-4">
              Select counties to get alerts for.
            </label>
            <div className="flex items-center mb-2">
              <Field
                type="checkbox"
                name="selectAll"
                id="selectAll"
                checked={values.selectAll}
                onChange={(e) => {
                  const newValue = !values.selectAll;
                  setFieldValue("selectAll", newValue);
                  handleSelectAll(setFieldValue, newValue);
                }}
              />
              <label htmlFor="selectAll" className="ml-2 text-glaucous-200">
                Select All
              </label>
            </div>
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 glaucous-200">
              {Array.from({ length: columns }, (col, index) => (
                <div key={index}>
                  {counties
                    .slice(index * columnWidth, (index + 1) * columnWidth)
                    .map((county) => (
                      <div
                        key={county.id}
                        className="flex items-center mb-2 glaucous-200"
                      >
                        <Field
                          type="checkbox"
                          name="selectedCounties"
                          value={county.name}
                          id={`county-${county.id}`}
                          checked={values.selectedCounties.includes(
                            county.name
                          )}
                        />
                        <label
                          htmlFor={`county-${county.id}`}
                          className="ml-2 text-glaucous-200"
                        >
                          {county.name}
                        </label>
                      </div>
                    ))}
                </div>
              ))}
            </div>
            <ErrorMessage
              name="selectedCounties"
              component="div"
              className="text-red-600"
            />
          </div>

          <button
            type="submit"
            className="text-white font-semibold bg-glaucous-200 px-5 py-3 rounded-full hover:bg-glaucous-900 hover:text-glaucous-200 transition duration-300 ease-in-out transform hover:scale-105 mb-4"
          >
            Submit
          </button>
        </Form>
      )}
    </Formik>
  );
};

export default AlertForm;
